import propTypes from 'prop-types';

const PostInfoTitleSecondary = ({ title }) => {
    return (
        <h2 className="post-title-h2-primary">
            {title}
        </h2>
    );
};

export default PostInfoTitleSecondary;

PostInfoTitleSecondary.propTypes = {
    title: propTypes.string.isRequired
}