import React from 'react';
import { Col } from 'react-bootstrap';

const Pvideo = () => {
  return (
    <div className='mt-3 d-none d-md-block'
      style={{
        height: '100vh',
        width: '100%',
        position: 'relative',
        backgroundColor: '#FFF',
      }}
    >
        <div className="h-100 align-items-center justify-content-center">
          <Col md={12} className="h-100">
            <div
              style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                src="images/video-home.mp4"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </div>
    </div>
  );
};

export default Pvideo;
