import propTypes from "prop-types";

/**
 * A function component that takes an array of text body
 * and renders a <ul> with a <li> for each item.
 *
 * @param {{ body: { id: number, text: string }[] }} props
 */
const PostInfoListPoint = ({ body }) => (
    <ul className="mx-2">
        {body.map(({ id, text }) => (
            <li key={id} className="post-p">{text}</li>
        ))}
    </ul>
);

export default PostInfoListPoint;

PostInfoListPoint.propTypes = {
    body: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.number.isRequired,
            text: propTypes.string.isRequired
        }),
    ).isRequired
}