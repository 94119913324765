import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import propTypes from 'prop-types';

const BlogCarousel = ({ posts, size, justify }) => {
    const navigate = useNavigate();
    const handlePostClick = useCallback((postId) => navigate(`/blog/post/${postId}`), [navigate]);
    const heightSize = {
        small: "270px",
        medium: "370px",
        large: "450px",
    }
    const justifyContent = {
        start: "justify-content-start",
        center: "justify-content-center",
        end: "justify-content-end",
    }

    return (
        <Row
            id="blogRow"
            lg={3}
            className= { `align-content-center mx-lg-0 px-lg-0 px-5 my-1 my-lg-0 d-flex gap-3 gap-lg-2  ${justifyContent[justify]}` }
            style={{ height: heightSize[size] }}
        >
            {posts.map(({ id, title, cardImgPath, resume }) => (
                <Col
                    id="blogCol"
                    key={id}
                >
                    <Card
                        id="blogCard"
                        className='w-100 h-100'
                        onClick={() => handlePostClick(id)}
                    >
                        <Card.Img
                            variant="top"
                            src={cardImgPath.path}
                            alt={cardImgPath.alt}
                            className="w-100 h-100"
                        />
                        <Card.Body
                            className={`position-relative p-3 ${id % 2 === 0 ? "bg-green-light-cu-2" : "bg-white-light-cu"
                                }`}
                        >
                            <Card.Title>{title}</Card.Title>
                            {resume &&
                                <Card.Text className="pb-3 pt-2 p-lg-auto">
                                    {resume}
                                </Card.Text>}
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default BlogCarousel;

BlogCarousel.propTypes = {
    size: propTypes.oneOf(['small', 'medium', 'large']).isRequired,
    justify : propTypes.oneOf(['start', 'center', 'end']).isRequired,
    posts: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.number.isRequired,
            title: propTypes.string.isRequired,
            resume: propTypes.string,
            cardImgPath: propTypes.shape({
                path: propTypes.string.isRequired,
                alt: propTypes.string.isRequired
            }).isRequired
        }),
    ).isRequired
}
