import React from 'react';

const TopBanner = () => {
  return (
    <div className='d-none d-md-block' style={{ backgroundColor: '#8FB93E', color: '#000', textAlign: 'center', padding: '10px 0' }}>
      <a href="tel:18704556742" style={{ color: '#000', textDecoration: 'none' }} className='font-jura'>Llámanos al (1) 870-455-6742</a>
    </div>
  );
};

export default TopBanner;
