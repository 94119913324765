import PostInfo from './post/PostInfo';
import PostNav from './post/PostNav';
import PostFooterBar from './post/PostFooterBar';
import { useBlogContext } from '../../context/useBlogContext';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import BlogCarousel from './BlogCarousel';
import PostHeadBar from './post/PostHeadBar';

const Post = () => {
  const { id } = useParams();
  const { loading, error, findById, posts } = useBlogContext();
  const [ post, setPost] =  useState(null);
  const [navItems, setNavItems] = useState([]);


  useEffect(() => {
    const post = findById(Number.parseInt(id));
    const postsToNavItems = posts.map((post) => ({
      id: post.id,
      title: post.title,
      url: `/blog/post/${post.id}`,
    }))

    setNavItems(postsToNavItems);
    setPost(post);
  }, [id, findById, posts]);

  if (loading) {
    return <p>Cargando post...</p>; 
  }

  if (error || !post) {
    return <p>Error al cargar el post</p>; 
  }

  return (
    <section className='d-flex flex-column w-100 bg-green-light-cu py-5 px-3 px-lg-5' id="post"  >
      <div className='container d-flex flex-column px-lg-5 gap-lg-1 pb-5' >
        <PostNav navItems={navItems} />
        <PostHeadBar from={post.from} date={post.date} readingTime={post.readingTime} />
        <PostInfo details={post.details} />
        <PostFooterBar item={navItems[0]} />
        <BlogCarousel size="medium" justify="start" posts={posts.map(post => ({id: post.id, title: post.title, cardImgPath: post.cardImgPath}))} />
      </div>
    </section >
  );
}

export default Post;